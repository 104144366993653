<template>
  <div>
 
    <div>
      <b-row class="admin-text" >
        <h5 class="mb-3">Manage Awards !</h5>
      </b-row>         
    </div>
  
    <div>
      <b-tabs content-class="mt-3">
        <!-- First Tab to Display Records -->
        <b-tab title="Admin Users" active>
          <b-row>
            <b-col sm="12" class="mb-3"><b-form-input v-model="SearchBy" placeholder="Search by name"></b-form-input></b-col>
            <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
          </b-row>
        <table class="table table-bordered table-striped">
          <thead>
            <tr class="text-center bg-success text-light">
              <th>ID</th>
              <th>Award Name</th>
              <th>Award Area</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="Data in SearchList" v-bind:key="Data.awards_id">
              <td>{{Data.awards_id}}</td>
              <td class="text-left">{{Data.awards_name}}</td>
              <td class="text-left">{{Data.awards_area}}</td>
              <td>
                <a href="#" class="text-success"><i class="fas fa-edit"></i></a>
              </td>
              <td>
                <a class="text-danger"><i class="fas fa-trash-alt" v-on:click="DelAwards(Data.awards_id)"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>          
        </b-tab>
        <!-- Second Tab to Create new Records -->        
        <b-tab title="Create New User">
          <b-row class="text-center">
            <b-col cols="8 my-4">
            <!-- <b-alert show variant="success"  v-if="MsgSuccess">Record created successfully!</b-alert>
            <b-alert show variant="danger"  v-if="MsgDanger">Error! Some Issue while create a new record!</b-alert>                  -->
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="AwardName">
                  <b-form-input
                    id="AwardName"
                    v-model="form.AwardName"
                    placeholder="Enter Award name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="AwardArea">
                  <b-form-select 
                    v-model="form.AwardArea" 
                    :options="options">
                  </b-form-select>
                </b-form-group>

                <b-form-textarea
                  id="textarea"
                  size="lg"
                  v-model="form.AwardDesc"
                  placeholder="Enter Award Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>  

                <b-form-group id="input-group-2" label-for="AwardPhoto">
                  <b-form-input
                    id="AwardPhoto"
                    v-model="form.AwardPhoto"
                    placeholder="Enter Award Photo url"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Reset</b-button>
              </b-form>        
            </b-col>
          </b-row>          

        </b-tab>
        <!-- Third Tab to see Inactive Records -->        
        <b-tab title="Inactive Records">
          <p>Inactive Records</p>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
        form: {
          AwardName: '',
          AwardArea: '',
          AwardDesc: '',
          AwardPhoto: '',
        },
        RespArray: [],
        RespAllAwards: [],
        RespArrayIns: [],
        show: true,
        SearchBy: '',
        options: [
          { value: null, text: 'Please select Award Area' },
          { value: 'Arts', text: 'Arts' },
          { value: 'Movies', text: 'Movies'},
        ], 
      }
    },
    mounted()
    {
      this.ReadAllAwards()
    },    
    methods: {
      onSubmit(event) {
      event.preventDefault()
      this.InsAwards()
        // alert(JSON.stringify(this.form))

      },
      ReadAllAwards(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllAwards')
        .then((resp)=>{
        this.RespAllAwards=resp.data.AllAwards;
      })
      },      
      InsAwards(){  
        const REQformData = new FormData();
        REQformData.append('awards_name', this.form.AwardName);
        REQformData.append('awards_area', this.form.AwardArea);
        REQformData.append('awards_description', this.form.AwardDesc);
        REQformData.append('awards_photo', this.form.AwardPhoto);                
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsAwards',REQformData)
        .then((resp)=>{
          this.RespArrayIns=resp.data.message;
          alert('Inserted Successfully!!')
          this.ReadAllAwards()
          this.ClearForms()
      })
      },
      DelAwards(awards_id){
       const InsformData = new FormData();
        InsformData.append('awards_id', awards_id);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelAwards', InsformData)
        .then((resp)=>{
        this.RespArray=resp.data.message;
        alert('Deleted Successfully!!')
        this.ReadAllAwards();
       }).catch(error => console.log(error));
       },
      ClearForms(){  
        this.form.AwardName = '';
        this.form.AwardArea = '';
        this.form.AwardDesc = '';
        this.form.AwardPhoto = '';        
      },                 
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      }
      
    },
    computed: {
    SearchList: function(){
        return this.RespAllAwards.filter((RespAllAwards) => {
        return RespAllAwards.awards_name.toLowerCase().match(this.SearchBy.toLowerCase())
      });
    },   
  }    
  }
</script>

<style scoped>
.admin-main .admin-form-login {
  padding: 40px 200px 80px 50px;    
}
</style>
